import './OneEventPage.css';
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { enrollEvent, getEventDetails } from '../../../../actions/volunteer';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import ShareHeader from '../../../LoaclComponents/shareHeader/ShareHeader';
import Loading from '../../../ui/loading';
import LoadingModal from '../../../Auth/loginSignPage/loadingPopUp/loadingPopUp';

const OneEventPage = () => {
  const [loadingModalShow, setLoadingModalShow] = useState(false);

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  let query = useQuery();
  const eventYear = query.get('at');
  const eventID = query.get('no');
  let userLogin = JSON.parse(localStorage.getItem('userLogin'));
  const { t } = useTranslation();
  let lng = JSON.parse(localStorage.getItem('language'))?.lang;
  const dispatch = useDispatch();
  useEffect(() => {
    if (userLogin?.Results[0]?.vln_id_flag === '2') {
      if (lng === 'arab') {
        dispatch(
          getEventDetails(1, userLogin?.Results[0]?.vln_id, eventID, eventYear)
        );
      } else {
        dispatch(
          getEventDetails(2, userLogin?.Results[0]?.vln_id, eventID, eventYear)
        );
      }
    }
  }, [lng, eventYear, eventID]);

  const vlnEventDetails = useSelector(
    (state) => state.vlnEventDetails.vlnEventDetails
  );
  const eventDetailsLoading = useSelector(
    (state) => state.vlnEventDetails.loading
  );
  const [vlnEventAllDetails, setVlnEventAllDetails] = useState([]);
  const [vlnEventsLoading, setEventsLoading] = useState(true);
  useEffect(() => {
    if (
      vlnEventDetails !== 'Input string was not in a correct format.' ||
      vlnEventAllDetails !== 'No data exists for the row or column.'
    ) {
      setVlnEventAllDetails(vlnEventDetails[0]);
      setEventsLoading(eventDetailsLoading);
    }
  }, [vlnEventDetails, eventDetailsLoading]);

  if (userLogin?.Results[0]?.vln_id_flag === '2') {
    return (
      <div
        className="relatives_display_for_event_details"
        style={lng === 'arab' ? { direction: 'rtl' } : null}
      >
        {vlnEventsLoading ? (
          <Loading height={'500px'} />
        ) : (
          <React.Fragment>
            <ShareHeader
              noShare={true}
              pageName={vlnEventAllDetails?.event_title}
            />
            <div className="submit_requ_header">
              <h3 style={{ color: 'gray' }}>
                {vlnEventAllDetails?.event_title}
              </h3>
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'flex-start',
                padding: '10px',
                width: '100%'
              }}
            >
              <label style={{ fontWeight: 'bold', textAlign: 'center' }}>
                {vlnEventAllDetails?.event_desc}
              </label>
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'flex-start',
                  gap: '20px'
                }}
              >
                <div
                  id="one_event"
                  className="relatives_display"
                  style={{
                    justifyContent: 'space-between',
                    marginTop: '70px',
                    boxShadow: '1px 0px 7px #80808040',
                    borderRadius: '10px'
                  }}
                >
                  <div className="row_for_event_details">
                    <label>{t('Event Date')}:</label>
                    <label
                      className="payment_numbers"
                      style={{ fontWeight: 'bolder' }}
                    >
                      {vlnEventAllDetails?.event_date}
                    </label>
                  </div>
                  <div className="row_for_event_details">
                    <label>{t('Event Location')}:</label>
                    <label style={{ fontWeight: 'bolder' }}>
                      {vlnEventAllDetails?.event_loc}
                    </label>
                  </div>
                  <div className="row_for_event_details">
                    <label>{t('Starts At')}:</label>
                    <label
                      className="payment_numbers"
                      style={{ fontWeight: 'bolder' }}
                    >
                      {vlnEventAllDetails?.event_start_date}
                    </label>
                  </div>
                  <div className="row_for_event_details">
                    <label>{t('Ends At')}:</label>
                    <label
                      className="payment_numbers"
                      style={{ fontWeight: 'bolder' }}
                    >
                      {vlnEventAllDetails?.event_end_date}
                    </label>
                  </div>
                  <div className="row_for_event_details">
                    <label>{t('From')}:</label>
                    <label
                      className="payment_numbers"
                      style={{ fontWeight: 'bolder' }}
                    >
                      {vlnEventAllDetails?.event_from_time}
                    </label>
                  </div>
                  <div className="row_for_event_details">
                    <label>{t('To')}:</label>
                    <label
                      className="payment_numbers"
                      style={{ fontWeight: 'bolder' }}
                    >
                      {vlnEventAllDetails?.event_to_time}
                    </label>
                  </div>
                </div>
                <button
                  disabled={vlnEventAllDetails?.vln_flag !== 'N'}
                  className="rollBtn"
                  onClick={() => {
                    setLoadingModalShow(true);
                    vlnEventAllDetails.vln_flag = enrollEvent(
                      eventID,
                      eventYear,
                      userLogin?.Results[0]?.vln_id,
                      setLoadingModalShow
                    );
                  }}
                >
                  {vlnEventAllDetails?.vln_message}
                </button>
              </div>
              <LoadingModal
                show={loadingModalShow}
                dialogClassName="modal-60w"
                backdrop="static"
              />
            </div>
          </React.Fragment>
        )}
      </div>
    );
  } else {
    window.location.replace('/profile');
  }
};

export default OneEventPage;
