import React, { useState, useEffect } from "react";
import icon1 from "../../../../assets/nation id.png";
import icon8 from "../../../../assets/upload.png";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import "./vlnAttachmentsForm.css";
import LoadingModal from "../../../Auth/loginSignPage/loadingPopUp/loadingPopUp";
import { uploadEidDetails } from "../../../../actions/volunteer";
import {
  applyValidation,
  resetValidation,
} from "../../../../handlers/formValidation";
import { toastifyMessage } from "../../../../handlers/toastifyMessage";
import Loading from "../../../ui/loading";

const NationalForm = (props) => {
  const { t } = useTranslation();
  let lng = JSON.parse(localStorage.getItem("language"))?.lang;
  let userLogin = JSON.parse(localStorage.getItem("userLogin"));
  const dispatch = useDispatch();
  const [idWeNeed, setIdWeNeed] = useState(props.vlnID);

  const [profileLoading, setProfileLoading] = useState(false);
  useEffect(() => {
    setIdWeNeed(props.vlnID);
  }, [props.vlnID]);
  const validateEmiratesId = (eId) => {
    const re = /^[0-9]{15}$/;
    return re.test(eId);
  };
  const [loadingModalShow, setLoadingModalShow] = useState(false);
  const goBack = () => {
    props.setView(props.view - 1)
  }
  const handleChange = (e) => {
    props.setNationalInfo({...props.nationalInfo, eid: {...props.nationalInfo.eid, [e.target.name]: e.target.value}});
  };
  useEffect(() => {
    if(!profileLoading)
    {
      if(props.nationalDocReader?.result)
    {
      document.getElementById(
        "for_upl"
      ).style.backgroundImage = `url(${props.nationalDocReader.result})`;
      document.getElementById('upload_icon').style.display='none'
      document.getElementById("for_upl").style.backgroundPosition = "center";
      document.getElementById("for_upl").style.backgroundSize = "cover";
      document.getElementById("for_upl").style.border = "1px solid white";
    }

    if(props.nationalDocBackReader?.result)
    {
      document.getElementById(
        "for_upl_back"
      ).style.backgroundImage = `url(${props.nationalDocBackReader.result})`;
      document.getElementById('upload_icon_back').style.display='none'
      document.getElementById("for_upl_back").style.backgroundPosition = "center";
      document.getElementById("for_upl_back").style.backgroundSize = "cover";
      document.getElementById("for_upl_back").style.border = "1px solid white";
    }
    }
  }, [profileLoading])
  
  const handleSubmit = (e) => {
    e.preventDefault();
    
    setLoadingModalShow(true);
    let userData = {
      volunteerID: idWeNeed,
      eid: {
        nationalID: props.nationalInfo.eid.nationalID,
        placeOfIssuance: props.nationalInfo.eid.placeOfIssuance,
        startDate: props.nationalInfo.eid.startDate.split("-").reverse().join("/"),
        endDate: props.nationalInfo.eid.endDate.split("-").reverse().join("/"),
        fileType: [props.nationalDoc.fileType, props.nationalDocBack.fileType],
        attachment: [props.nationalDoc.attachment, props.nationalDocBack.attachment],
      },
    };
    if (props.nationalDoc.fileType==='' || props.nationalDoc.attachment==='') {
      setLoadingModalShow(false);
      return toastifyMessage("Upload your Attachment", undefined, "warning");
    }
    if (props.nationalInfo.eid.nationalID === '' || props.nationalInfo.eid.nationalID === null) {
      setLoadingModalShow(false);
      toastifyMessage("EmiratesID field cannot be empty!", undefined, "warning")
      return false;
    } else if (!validateEmiratesId(props.nationalInfo.eid.nationalID)) {
      setLoadingModalShow(false);
      toastifyMessage(
        "EmiratesID number should be 15 digits long",
        undefined,
        "warning"
      );
      return false;
    } else {
      dispatch(uploadEidDetails(userData, setLoadingModalShow, props.setView));
      props.setNationalInfo(userData)
      props.setView(props.view + 1)
    }
  };
    return (
      <div
        className="local_aid_info_form_page default_min_height"
        style={lng === "arab" ? { direction: "rtl" } : null}
      >
        <label
          style={{
            padding: "10px 10px 10px 20px",
            fontWeight: "bold",
            width: "100%",
            textAlign: "center",
          }}
        >
          {t("Insert your National details")}
        </label>
        <form className="local_aid_info_form" onSubmit={handleSubmit}>
        <div className="uploading_input">
          {profileLoading?
          <Loading height='250px' mediaQ='200px' width='297px'/>:
            <label id="for_upl" htmlFor="myInputID">
            <img src={icon8} id='upload_icon' alt="upload" className="upload_icon" />
            </label>}
            <input type="file" id="myInputID" accept=".pdf" onChange={() => {props.handleUpLoadChange(setProfileLoading, props.nationalDocReader, "myInputID", "for_upl", "fileName", "upload_icon", props.setNationalDoc)}} />
            <label id="fileName">{t("Upload your National ID (Front)")}</label>
          </div>
          <div className="uploading_input">
          {profileLoading?
          <Loading height='250px' mediaQ='200px' width='297px'/>:
            <label id="for_upl_back" htmlFor="myInputIDBack">
            <img src={icon8} id='upload_icon_back' alt="upload" className="upload_icon" />
            </label>}
            <input type="file" id="myInputIDBack" accept=".pdf" onChange={() => {props.handleUpLoadChange(setProfileLoading, props.nationalDocBackReader, "myInputIDBack", "for_upl_back", "fileNameBack", "upload_icon_back", props.setNationalDocBack)}} />
            <label id="fileNameBack">{t("Upload your National ID (Back)")}</label>
          </div>
          <div className="input_wraper" style={{ alignSelf: "center" }}>
            <div className="local_aid_input_icon_wraper">
              <img src={icon1} className="local_aid_icon" alt="form_icon" />
            </div>
            <input
              type="text"
              onChange={handleChange}
              className={props?.nationalInfo?.eid?.nationalID? 'payment_numbers local_aid_input': lng==='arab'? 'force_arabic_font local_aid_input':'payment_numbers local_aid_input'}
              value={props?.nationalInfo?.eid?.nationalID??userLogin?.Results[0].emiratesID}
              name="nationalID"
              placeholder={t("National ID")}
              pattern="[0-9]*"
              maxLength={15}
              disabled= {userLogin?.Results[0].emiratesID}
            />
          </div>
          <div className="input_wraper" style={{ alignSelf: "center" }}>
            <div className="local_aid_input_icon_wraper">
              <img src={icon1} className="local_aid_icon" alt="form_icon" />
            </div>
            <input
              type="text"
              onChange={handleChange}
              className={lng==='arab'? "local_aid_input force_arabic_font":"local_aid_input"}
              value={props.nationalInfo.eid.placeOfIssuance}
              name="placeOfIssuance"
              placeholder={t("Place of Issuance")}
              required
              onInvalid={applyValidation}
              onInput={resetValidation}
            />
          </div>
          <label
            style={{ width: "90%", alignSelf: "center", marginBottom: "-25px" }}
          >
            {t("Start Date")}
          </label>
          <div className="input_wraper" style={{ alignSelf: "center" }}>
            <div className="local_aid_input_icon_wraper">
              <img src={icon1} className="local_aid_icon" alt="form_icon" />
            </div>
            <input
              type="date"
              onChange={handleChange}
              className="local_aid_input"
              value={props.nationalInfo.eid.startDate? props.nationalInfo.eid.startDate.split("/").reverse().join("-"): props.nationalInfo.eid.startDate}
              name="startDate"
              placeholder={t("Start date")}
              required
              onInvalid={applyValidation}
              onInput={resetValidation}
              onKeyDown={(e) => {
                e.preventDefault();
             }}
              min={`${new Date().getFullYear() - 100}-01-02`}
              max={`${new Date().getFullYear()}-${ (new Date().getMonth() + 1).toString().length===1? '0'+(new Date().getMonth() + 1):new Date().getMonth() + 1}-${new Date().getDate().toString().length===1? '0'+new Date().getDate():new Date().getDate()}`}
            />
          </div>
          <label
            style={{ width: "90%", alignSelf: "center", marginBottom: "-25px" }}
          >
            {t("End Date")}
          </label>
          <div className="input_wraper" style={{ alignSelf: "center" }}>
            <div className="local_aid_input_icon_wraper">
              <img src={icon1} className="local_aid_icon" alt="form_icon" />
            </div>
            <input
              type="date"
              onChange={handleChange}
              className="local_aid_input"
              value={props.nationalInfo.eid.endDate? props.nationalInfo.eid.endDate.split("/").reverse().join("-"): props.nationalInfo.eid.endDate}
              name="endDate"
              placeholder={t("End date")}
              required
              onInvalid={applyValidation}
              onInput={resetValidation}
              onKeyDown={(e) => {
                e.preventDefault();
             }}
              min={`${new Date().getFullYear() - 100}-01-02`}
            />
          </div>
          <button
            id="submitBtn_for_local_aid"
            type="submit"
            className="local_aid_submit"
          >
            <span style={{ flex: "16" }}>{t("Next")}</span>
            {lng === "arab" ? (
              <i className="fas fa-arrow-left"></i>
            ) : (
              <i className="fas fa-arrow-right"></i>
            )}
          </button>
          <button
            id="submitBtn_for_local_aid"
            className="local_aid_submit"
            onClick={() => goBack()}
          >
            <span style={{ flex: "16" }}>{t("Go Back")}</span>
            {lng === "arab" ? (
              <i className="fas fa-arrow-left"></i>
            ) : (
              <i className="fas fa-arrow-right"></i>
            )}
          </button>
        </form>
        <LoadingModal
          show={loadingModalShow}
          dialogClassName="modal-60w"
          backdrop="static"
        />
      </div>
    );
};

export default NationalForm;
