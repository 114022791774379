import React, { useState, useEffect } from 'react';
import icon8 from '../../../../assets/upload.png';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import './vlnAttachmentsForm.css';
import LoadingModal from '../../../Auth/loginSignPage/loadingPopUp/loadingPopUp';
import { uploadProfilePic } from '../../../../actions/volunteer';
import {
  applyValidation,
  resetValidation
} from '../../../../handlers/formValidation';
import Loading from '../../../ui/loading';
import { toastifyMessage } from '../../../../handlers/toastifyMessage';

const ProfileImgForm = (props) => {
  const { t } = useTranslation();
  let lng = JSON.parse(localStorage.getItem('language'))?.lang;
  let userLogin = JSON.parse(localStorage.getItem('userLogin'));
  const dispatch = useDispatch();
  const [idWeNeed, setIdWeNeed] = useState(props.vlnID);
  const [profileLoading, setProfileLoading] = useState(false);

  useEffect(() => {
    setIdWeNeed(props.vlnID);
  }, [props.vlnID]);
  const [loadingModalShow, setLoadingModalShow] = useState(false);

  // const handleUpLoadChange = (e) => {
  //   var fileInput = document.getElementById("myInputID");
  //   setProfileLoading(true)
  //   if(fileInput.files[0]) {
  //     props.profileImgReader.readAsDataURL(fileInput.files[0]);
  //   props.profileImgReader.onload = function () {
  //     let type = fileInput.files[0].type.split("/").slice(1).shift();
  //     let imgCode = props.profileImgReader.result.split(",").slice(1).shift();
  //     setProfileLoading(false)
  //     document.getElementById("fileName").innerHTML = fileInput.files[0].name;
  //     document.getElementById(
  //       "for_upl"
  //     ).style.backgroundImage = `url(${props.profileImgReader.result})`;
  //     document.getElementById('upload_icon').style.display='none'
  //     document.getElementById("for_upl").style.backgroundPosition = "center";
  //     document.getElementById("for_upl").style.backgroundSize = "cover";
  //     document.getElementById("for_upl").style.border = "1px solid white";
  //     props.setUserProfile({ fileType: type, attachment: imgCode });
  //   };
  //   props.profileImgReader.onerror = function (error) {
  //   };
  //   } else {
  //     setProfileLoading(false)
  //   }

  // };
  useEffect(() => {
    if (props.profileImgReader?.result) {
      document.getElementById(
        'for_upl'
      ).style.backgroundImage = `url(${props.profileImgReader.result})`;
      document.getElementById('upload_icon').style.display = 'none';
      document.getElementById('for_upl').style.backgroundPosition = 'center';
      document.getElementById('for_upl').style.backgroundSize = 'cover';
      document.getElementById('for_upl').style.border = '1px solid white';
    }
  }, []);
  const handleSubmit = (e) => {
    e.preventDefault();
    setLoadingModalShow(true);
    let userData = {
      volunteerID: idWeNeed,
      picture: props.userProfile
    };
    if (
      props.userProfile.fileType === '' ||
      props.userProfile.attachment === ''
    ) {
      setLoadingModalShow(false);
      return toastifyMessage('Upload your Attachment', undefined, 'warning');
    }
    props.setPictureInfo(userData);
    props.setView(props.view + 1);
    dispatch(uploadProfilePic(userData, setLoadingModalShow, props.setView));
  };
  return (
    <div
      className="local_aid_info_form_page default_min_height"
      style={lng === 'arab' ? { direction: 'rtl' } : null}
    >
      <label
        style={{
          padding: '10px 10px 10px 20px',
          fontWeight: 'bold',
          width: '100%',
          textAlign: 'center'
        }}
      >
        {t('Upload your picture')}
      </label>
      <form className="local_aid_info_form" onSubmit={handleSubmit}>
        <div className="uploading_input">
          {profileLoading ? (
            <Loading height="250px" mediaQ="200px" width="297px" />
          ) : (
            <label id="for_upl" htmlFor="myInputID">
              <img
                src={icon8}
                id="upload_icon"
                alt="upload"
                className="upload_icon"
              />
            </label>
          )}
          <input
            type="file"
            id="myInputID"
            accept="image/JPG, image/jpg, image/jpeg, image/JPEG"
            onChange={() => {
              props.handleUpLoadChange(
                setProfileLoading,
                props.profileImgReader,
                'myInputID',
                'for_upl',
                'fileName',
                'upload_icon',
                props.setUserProfile
              );
            }}
          />
          <label id="fileName"></label>
          <label
            style={{
              padding: '10px 10px 10px 20px',
              fontWeight: 'bold',
              width: '100%',
              textAlign: 'center',
              fontSize: '10px',
              color: 'red'
            }}
          >
            {t('*JPG format')}
          </label>
        </div>

        <button
          id="submitBtn_for_local_aid"
          type="submit"
          className="local_aid_submit"
        >
          <span style={{ flex: '16' }}>{t('Next')}</span>
          {lng === 'arab' ? (
            <i className="fas fa-arrow-left"></i>
          ) : (
            <i className="fas fa-arrow-right"></i>
          )}
        </button>
      </form>
      <LoadingModal
        show={loadingModalShow}
        dialogClassName="modal-60w"
        backdrop="static"
      />
    </div>
  );
};

export default ProfileImgForm;
