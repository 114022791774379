import React, { useState, useEffect } from "react";
import icon8 from "../../../../assets/upload.png";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import "./VolunteerIntroForm.css";
import LoadingModal from "../../../Auth/loginSignPage/loadingPopUp/loadingPopUp";
import Loading from "../../../ui/loading";
import { toastifyMessage } from "../../../../handlers/toastifyMessage";
import Img from "../../../../assets/109.jpeg"
import { getVolunteerIntro } from "../../../../api";
import { uploadIntro } from "../../../../actions/volunteer";
const VolunteerIntroForm = (props) => {
  const { t } = useTranslation();
  let lng = JSON.parse(localStorage.getItem("language"))?.lang;
  const dispatch = useDispatch();
  const [idWeNeed, setIdWeNeed] = useState(props.vlnID);
  const [profileLoading, setProfileLoading] = useState(false);
  const [volunteerIntro, setVolunteerIntro] = useState(null);

  useEffect(() => {
    getVolunteerIntro(lng === "en"? 2:1).then((res) => {
      setVolunteerIntro(res.data.result)

    })
  }, [])
  useEffect(() => {
    setIdWeNeed(props.vlnID);
  }, [props.vlnID]);
  const [loadingModalShow, setLoadingModalShow] = useState(false);

  
  //TODO check why api returns error
  useEffect(() => {
    if(props.volunteerIntroReader?.result)
    {
      document.getElementById(
        "for_upl"
      ).style.backgroundImage = Img;
      document.getElementById('upload_icon').style.display='none'
      document.getElementById("for_upl").style.backgroundPosition = "center";
      document.getElementById("for_upl").style.backgroundSize = "cover";
      document.getElementById("for_upl").style.border = "1px solid white";
    }
  }, [profileLoading])
  const handleSubmit = (e) => {
    e.preventDefault();
    setLoadingModalShow(true);
    let userData = {
      volunteerID: idWeNeed,
      introduction: props.volunteerIntro,
    };
    // if (props.volunteerIntro.fileType==='' || props.volunteerIntro.attachment==='') {
    //   setLoadingModalShow(false);
    //   return toastifyMessage("Upload your Attachment", undefined, "warning");
    // }
    props.setIntroInfo(userData)
    props.setView(props.view + 2)
    dispatch(uploadIntro(userData, setLoadingModalShow, props.setView));
  };
  const goBack = () => {
    props.setView(props.view - 1)
  }
  const redirectToPDF = () => {
    window.open(volunteerIntro, "_blank")
  }
    return (
      <div
        className="local_aid_info_form_page default_min_height"
        style={lng === "arab" ? { direction: "rtl" } : null}
      >
        <label
          style={{
            padding: "10px 10px 10px 20px",
            fontWeight: "bold",
            width: "100%",
            textAlign: "center",
          }}
        >
          {t("Fill in all the document data, approve it, and then re-upload it.")}
        </label>
        <button className="pdfVolunteerBtn" onClick={() => redirectToPDF()}>{t("Download PDF")}</button>
        <form className="local_aid_info_form" onSubmit={handleSubmit}>
          
          <div className="uploading_input">
          {profileLoading?
          <Loading height='250px' mediaQ='200px' width='297px'/>:
            <label id="for_upl" htmlFor="myInputID">
              <img src={icon8} id='upload_icon' alt="upload" className="upload_icon" />
            </label>}
            <input type="file" id="myInputID" onChange={() => {props.handleUpLoadChange(setProfileLoading, props.volunteerIntroReader, "myInputID", "for_upl", "fileName", "upload_icon", props.setVolunteerIntro)}}/>
            <label id="fileName"></label>
            

          </div>
        
          
          <button
            id="submitBtn_for_local_aid"
            type="submit"
            className="local_aid_submit"
          >
            <span style={{ flex: "16" }}>{t("Next")}</span>
            {lng === "arab" ? (
              <i className="fas fa-arrow-left"></i>
            ) : (
              <i className="fas fa-arrow-right"></i>
            )}
          </button>
          <button
            id="submitBtn_for_local_aid"
            className="local_aid_submit"
            onClick={() => goBack()}
          >
            <span style={{ flex: "16" }}>{t("Go Back")}</span>
            {lng === "arab" ? (
              <i className="fas fa-arrow-left"></i>
            ) : (
              <i className="fas fa-arrow-right"></i>
            )}
          </button>
        </form>
        <LoadingModal
          show={loadingModalShow}
          dialogClassName="modal-60w"
          backdrop="static"
        />
      </div>
    );
};

export default VolunteerIntroForm;
