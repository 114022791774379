import React, { useState, useEffect } from 'react';
import icon3 from '../../../../assets/ntionality.png';
import icon2 from '../../../../assets/edducation.png';
import icon8 from '../../../../assets/upload.png';
import icon1 from '../../../../assets/nation id.png';

import icon10 from '../../../../assets/occupation.png';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import './vlnAttachmentsForm.css';
import LoadingModal from '../../../Auth/loginSignPage/loadingPopUp/loadingPopUp';
import {
  getAllCountries,
  getEdu,
  uploadEduData
} from '../../../../actions/volunteer';
import {
  applyValidation,
  resetValidation
} from '../../../../handlers/formValidation';
import { toastifyMessage } from '../../../../handlers/toastifyMessage';
import Loading from '../../../ui/loading';
// TODO Split eudcation in two
const EduForm = (props) => {
  const { t } = useTranslation();
  let lng = JSON.parse(localStorage.getItem('language'))?.lang;
  let userLogin = JSON.parse(localStorage.getItem('userLogin'));

  const dispatch = useDispatch();
  useEffect(() => {
    if (lng === 'arab') {
      dispatch(getAllCountries(1));
      dispatch(getEdu(1));
    } else {
      dispatch(getAllCountries(2));
      dispatch(getEdu(2));
    }
  }, [lng]);
  const [idWeNeed, setIdWeNeed] = useState(props.vlnID);
  useEffect(() => {
    setIdWeNeed(props.vlnID);
  }, [props.vlnID]);
  const [loadingModalShow, setLoadingModalShow] = useState(false);
  const vlnEducation = useSelector((state) => state.vlnEdu);
  const [vlnEducationAll, setVlnEducationAll] = useState(
    vlnEducation?.allDegreeDetails
  );
  const [vlnEducationTypes, setVlnEducationTypes] = useState(
    vlnEducation?.allDegreeDetails
  );
  useEffect(() => {
    setVlnEducationAll(vlnEducation?.allDegreeDetails);
    setVlnEducationTypes(vlnEducation?.allDegreeTypes);
  }, [vlnEducation]);

  const vlnCountries = useSelector((state) => state.vlnCountries);
  const [vlnAllCountries, setVlnAllCountries] = useState(vlnCountries);
  useEffect(() => {
    setVlnAllCountries(vlnCountries);
  }, [vlnCountries]);
  const [profileLoading, setProfileLoading] = useState(false);
  const handleChange = (e) => {
    props.setEducationInfo({
      ...props.educationInfo,
      education: {
        ...props.educationInfo.education,
        [e.target.name]: e.target.value
      }
    });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    setLoadingModalShow(true);
    let userData = {
      volunteerID: idWeNeed,
      education: {
        eduLevelID: props.educationInfo.education.eduLevelID,
        qlfID: props.educationInfo.education.qlfID,
        placeOfIssuance: props.educationInfo.education.placeOfIssuance,
        country: props.educationInfo.education.country,
        dateOfIssuance: props.educationInfo.education.dateOfIssuance
          ?.split('-')
          .reverse()
          .join('/'),
        fileType: props.education.fileType,
        attachment: props.education.attachment
      }
    };
    // if (props.education.fileType === '' || props.education.attachment === '') {
    //   setLoadingModalShow(false);
    //   return toastifyMessage('Upload your Attachment', undefined, 'warning');
    // }
    dispatch(uploadEduData(userData, setLoadingModalShow, props.setView));
    props.setEducationInfo(userData);
    props.setView(props.view + 1);
  };
  const goBack = () => {
    if (
      props.volunteerNationality === '101' ||
      userLogin?.Results[0]?.nationality === '101'
    )
      props.setView(props.view - 2);
    else props.setView(props.view - 1);
  };
  useEffect(() => {
    if (!profileLoading) {
      if (props.educationReader?.result) {
        document.getElementById(
          'for_upl'
        ).style.backgroundImage = `url(${props.educationReader.result})`;
        document.getElementById('upload_icon').style.display = 'none';
        document.getElementById('for_upl').style.backgroundPosition = 'center';
        document.getElementById('for_upl').style.backgroundSize = 'cover';
        document.getElementById('for_upl').style.border = '1px solid white';
      }
    }
  }, [profileLoading]);
  return (
    <div
      className="local_aid_info_form_page default_min_height"
      style={lng === 'arab' ? { direction: 'rtl' } : null}
    >
      <label
        style={{
          padding: '10px 10px 10px 20px',
          fontWeight: 'bold',
          width: '100%',
          textAlign: 'center'
        }}
      >
        {t('Insert your Education details')}
      </label>
      <form className="local_aid_info_form" onSubmit={handleSubmit}>
        <div className="uploading_input">
          {profileLoading ? (
            <Loading height="250px" mediaQ="200px" width="297px" />
          ) : (
            <label id="for_upl" htmlFor="myInputID">
              <img
                src={icon8}
                id="upload_icon"
                alt="upload"
                className="upload_icon"
              />
            </label>
          )}
          <input
            type="file"
            id="myInputID"
            accept=".pdf"
            onChange={() => {
              props.handleUpLoadChange(
                setProfileLoading,
                props.educationReader,
                'myInputID',
                'for_upl',
                'fileName',
                'upload_icon',
                props.setEducation
              );
            }}
          />
          <label id="fileName">{t('Upload your Educational License')}</label>
        </div>
        <div className="input_wraper" style={{ alignSelf: 'center' }}>
          <div className="local_aid_input_icon_wraper">
            <img src={icon1} className="local_aid_icon" alt="form_icon" />
          </div>
          <input
            type="text"
            onChange={handleChange}
            className={
              lng === 'arab'
                ? 'local_aid_input force_arabic_font'
                : 'local_aid_input'
            }
            value={props.educationInfo.education.placeOfIssuance}
            name="placeOfIssuance"
            placeholder={t('Place of Issuance')}
            // required
            // onInvalid={applyValidation}
            // onInput={resetValidation}
          />
        </div>
        <label
          style={{ width: '90%', alignSelf: 'center', marginBottom: '-25px' }}
        >
          {t('Date of Issuance')}
        </label>
        <div className="input_wraper" style={{ alignSelf: 'center' }}>
          <div className="local_aid_input_icon_wraper">
            <img src={icon10} className="local_aid_icon" alt="form_icon" />
          </div>
          <input
            type="date"
            onChange={handleChange}
            className="local_aid_input"
            value={
              props.educationInfo.education.dateOfIssuance
                ? props.educationInfo.education.dateOfIssuance
                    .split('/')
                    .reverse()
                    .join('-')
                : props.educationInfo.education.dateOfIssuance
            }
            name="dateOfIssuance"
            placeholder={t('Date of Issuance')}
            // required
            // onInvalid={applyValidation}
            // onInput={resetValidation}
            onKeyDown={(e) => {
              e.preventDefault();
            }}
            min={`${new Date().getFullYear() - 100}-01-02`}
            max={`${new Date().getFullYear()}-${
              (new Date().getMonth() + 1).toString().length === 1
                ? '0' + (new Date().getMonth() + 1)
                : new Date().getMonth() + 1
            }-${
              new Date().getDate().toString().length === 1
                ? '0' + new Date().getDate()
                : new Date().getDate()
            }`}
          />
        </div>
        <div className="input_wraper" style={{ alignSelf: 'center' }}>
          <img src={icon2} className="local_aid_icon" alt="form_icon" />
          <select
            value={props.educationInfo.education.eduLevelID}
            onChange={handleChange}
            // required
            onInvalid={applyValidation}
            onInput={resetValidation}
            name="eduLevelID"
            className="selectedCourse"
          >
            <option value="" disabled defaultValue>
              {t('Education Type')}
            </option>
            {vlnEducationTypes?.map((item, i) => (
              <option key={i} value={item.edu_level_id}>
                {item.edu_level_name}
              </option>
            ))}
          </select>
        </div>

        <div className="input_wraper" style={{ alignSelf: 'center' }}>
          <img src={icon2} className="local_aid_icon" alt="form_icon" />
          <select
            disabled={props.educationInfo.education.eduLevelID === ''}
            value={props.educationInfo.education.qlfID}
            onChange={handleChange}
            // required
            onInvalid={applyValidation}
            onInput={resetValidation}
            name="qlfID"
            className="selectedCourse"
          >
            <option value="" disabled defaultValue>
              {t('Education Level')}
            </option>
            {vlnEducationAll
              ?.filter(
                (item) =>
                  item.edu_level_ID === props.educationInfo.education.eduLevelID
              )
              .map((item, i) => (
                <option key={i} value={item.qlfID}>
                  {item.name}
                </option>
              ))}
          </select>
        </div>

        <div className="input_wraper" style={{ alignSelf: 'center' }}>
          <img src={icon3} className="local_aid_icon" alt="form_icon" />
          <select
            value={props.educationInfo.education.country}
            onChange={handleChange}
            name="country"
            className="selectedCourse"
            // required
            onInvalid={applyValidation}
            onInput={resetValidation}
          >
            <option value="" disabled defaultValue>
              {t('Country')}
            </option>
            {vlnAllCountries?.map((item, i) => (
              <option key={item.countryID} value={item.countryID}>
                {item.countryName}
              </option>
            ))}
          </select>
        </div>
        <button
          id="submitBtn_for_local_aid"
          type="submit"
          className="local_aid_submit"
        >
          <span style={{ flex: '16' }}>{t('Next')}</span>
          {lng === 'arab' ? (
            <i className="fas fa-arrow-left"></i>
          ) : (
            <i className="fas fa-arrow-right"></i>
          )}
        </button>
        <button
          id="submitBtn_for_local_aid"
          className="local_aid_submit"
          onClick={() => goBack()}
        >
          <span style={{ flex: '16' }}>{t('Go Back')}</span>
          {lng === 'arab' ? (
            <i className="fas fa-arrow-left"></i>
          ) : (
            <i className="fas fa-arrow-right"></i>
          )}
        </button>
      </form>
      <LoadingModal
        show={loadingModalShow}
        dialogClassName="modal-60w"
        backdrop="static"
      />
    </div>
  );
};

export default EduForm;
