import React, { useState, useEffect } from 'react';
import './Register.css';
import MainForm from './vlnMainForm/vlnMainForm';
import ProfileImgForm from './vlnAttachmentsForm/profileImgForm';
import JobForm from './vlnAttachmentsForm/JobForm';
import EduForm from './vlnAttachmentsForm/EduForm';
import NationalForm from './vlnAttachmentsForm/NationalForm';
import ResiForm from './vlnAttachmentsForm/ResiForm';
import PassForm from './vlnAttachmentsForm/PassForm';
import LanguagesForm from './vlnLanguagesForm/LanguagesForm';
import InterestsForm from './vlnInterestsForm/InterestsForm';
import SkillsForm from './vlnSkillsForm/SkillsForm';
import { useHistory } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import { getAllBranches } from '../../../actions/volunteer';
import ShareHeader from '../../LoaclComponents/shareHeader/ShareHeader';
import { t } from 'i18next';
import { useLocation } from 'react-router-dom';
import Loading from '../../ui/loading';
import {
  checkingUserRegisterStepss,
  volunteerSuccessRegMessage
} from '../../../api';
import VolunteerIntroForm from './vlnAttachmentsForm/volunteerIntroForm';
const Register = (props) => {
  let lng = JSON.parse(localStorage.getItem('language'))?.lang;
  let userLogin = JSON.parse(localStorage.getItem('userLogin'));
  const history = useHistory();
  const [view, setView] = useState(0);
  const [loadingDisplay, setLoadingDisplay] = useState(true);
  const [vlnID, setVlnId] = useState('');
  const dispatch = useDispatch();
  const location = useLocation();
  const [userProfile, setUserProfile] = useState({
    fileType: '',
    attachment: ''
  });

  const [nationalDoc, setNationalDoc] = useState({
    fileType: '',
    attachment: ''
  });
  const [nationalDocBack, setNationalDocBack] = useState({
    fileType: '',
    attachment: ''
  });
  const [nationalInfo, setNationalInfo] = useState({
    eid: {
      nationalID: userLogin?.Results[0]?.emiratesID
        ? userLogin?.Results[0]?.emiratesID
        : '',
      placeOfIssuance: '',
      startDate: null,
      endDate: null,
      fileType: [nationalDoc.fileType, nationalDocBack.fileType],
      attachment: [nationalDoc.attachment, nationalDocBack.attachment]
    }
  });
  const [passportInfo, setPassportInfo] = useState({
    passport: {
      passportDocumentID: '',
      placeOfIssuance: '',
      startDate: null,
      endDate: null,
      fileType: '',
      attachment: ''
    }
  });
  const [residenceInfo, setResidenceInfo] = useState({
    residence: {
      residenceDocID: '',
      placeOfIssuance: '',
      startDate: null,
      endDate: null,
      fileType: '',
      attachment: ''
    }
  });
  const [educationInfo, setEducationInfo] = useState({
    education: {
      eduLevelID: '',
      qlfID: '',
      placeOfIssuance: '',
      country: '',
      dateOfIssuance: null,
      fileType: '',
      attachment: ''
    }
  });
  const [jobInfo, setJobInfo] = useState({
    jobLicense: {
      jobID: null,
      endDate: null,
      fileType: '',
      attachment: ''
    }
  });
  const [pictureInfo, setPictureInfo] = useState({
    picture: {
      fileType: '',
      attachment: ''
    }
  });
  const [introInfo, setIntroInfo] = useState({
    introduction: {
      fileType: '',
      attachment: ''
    }
  });
  const [passport, setPassport] = useState({
    fileType: '',
    attachment: ''
  });

  const [residence, setResidence] = useState({
    fileType: '',
    attachment: ''
  });

  const [education, setEducation] = useState({
    fileType: '',
    attachment: ''
  });

  const [job, setJob] = useState({
    fileType: '',
    attachment: ''
  });
  const [volunteerIntro, setVolunteerIntro] = useState({
    fileType: '',
    attachment: ''
  });

  const [volunteerNationality, setVolunteerNationality] = useState(null);
  const [volunteerSuccessMessage, setVolunteerSuccessMessage] = useState(null);

  const [profileImgReader, setProfileImgReader] = useState(new FileReader());
  const [nationalDocReader, setNationalDocReader] = useState(new FileReader());
  const [nationalDocBackReader, setNationalDocBackReader] = useState(
    new FileReader()
  );
  const [passportReader, setPassportReader] = useState(new FileReader());
  const [residenceReader, setResidenceReader] = useState(new FileReader());
  const [educationReader, setEducationReader] = useState(new FileReader());
  const [volunteerIntroReader, setVolunteerIntroReader] = useState(
    new FileReader()
  );
  const [jobReader, setJobReader] = useState(new FileReader());

  const handleUpLoadChange = (
    setProfileLoading,
    fileReader,
    htmlName,
    imageName,
    fileName,
    uploadIcon,
    setImg
  ) => {
    var fileInput = document.getElementById(htmlName);
    setProfileLoading(true);
    if (fileInput.files[0]) {
      fileReader.readAsDataURL(fileInput.files[0]);
      fileReader.onload = function () {
        let type = fileInput.files[0].type.split('/').slice(1).shift();
        let imgCode = fileReader.result.split(',').slice(1).shift();
        setProfileLoading(false);
        document.getElementById(fileName).innerHTML = fileInput.files[0].name;
        document.getElementById(
          imageName
        ).style.backgroundImage = `url(${fileReader.result})`;
        document.getElementById(uploadIcon).style.display = 'none';
        document.getElementById(imageName).style.backgroundPosition = 'center';
        document.getElementById(imageName).style.backgroundSize = 'cover';
        document.getElementById(imageName).style.border = '1px solid white';
        setImg({ fileType: type, attachment: imgCode });
      };
      fileReader.onerror = function (error) {};
    } else {
      setProfileLoading(false);
    }
  };
  useEffect(() => {
    //for redirecting
    if (!userLogin) {
      history.push('/auth', { from: 'volunteer' });
    } else if (
      userLogin?.Results[0]?.vln_id &&
      userLogin?.Results[0]?.vln_id_flag === '2'
    ) {
      window.location.replace('/volunteer');
    } else if (
      userLogin?.Results[0]?.vln_id &&
      userLogin?.Results[0]?.vln_id_flag === '1'
    ) {
      checkingUserRegisterStepss(userLogin?.Results[0]?.vln_id)
        .then((res) => {
          if (res.data.status) {
            setLoadingDisplay(false);
            if (!res.data.data.completedPic) {
              setView(1);
            } else if (!res.data.data.completedEmiratesID) {
              setView(2);
            } else if (!res.data.data.completedPassport) {
              setView(3);
            } else if (
              !userLogin?.Results[0]?.nationality === '101' &&
              !res.data.data.completedResidence
            ) {
              setView(4);
            } else if (!res.data.data.completedEducation) {
              setView(5);
            }
            //  else if (!res.data.data.completedJob) {
            //   setView(7);
            // }
            else {
              setView(-1);
            }
          } else {
            setLoadingDisplay(false);
            window.location.replace('/profile');
          }
        })
        .catch((e) => {
          let number = Object.keys(e).length;
          if (number > 0) {
            setLoadingDisplay(false);
            window.location.replace('/profile');
          }
        });
      try {
        volunteerSuccessRegMessage(
          lng === 'arab' ? 1 : 2,
          userLogin?.Results[0]?.vln_id
        ).then((data) => {
          setVolunteerSuccessMessage(data.data.result);
        });
      } catch (e) {}
    } else {
      setLoadingDisplay(false);
      setView(0);
    }
  }, []);

  useEffect(() => {
    if (lng === 'arab') {
      dispatch(getAllBranches(1));
    } else {
      dispatch(getAllBranches(2));
    }
  }, [location.state?.stepNo]);

  if (loadingDisplay) {
    return <Loading height={'700px'} />;
  } else {
    return (
      <div
        className="local_aid_info_form_page"
        style={
          lng === 'arab'
            ? { direction: 'rtl', width: '100%' }
            : { width: '100%' }
        }
      >
        <ShareHeader pageName={t('Volunteer Register')} noShare={true} />
        {view === 0 ? (
          <MainForm
            setVolunteerNationality={setVolunteerNationality}
            setVlnId={setVlnId}
            setView={setView}
            index={props.location.state?.stepNo}
          />
        ) : view === 1 ? (
          <ProfileImgForm
            volunteerNationality={volunteerNationality}
            setPictureInfo={setPictureInfo}
            handleUpLoadChange={handleUpLoadChange}
            view={view}
            profileImgReader={profileImgReader}
            userProfile={userProfile}
            setUserProfile={setUserProfile}
            vlnID={userLogin?.Results[0]?.vln_id}
            setView={setView}
            index={props.location.state?.stepNo}
          />
        ) : view === 2 ? (
          <NationalForm
            nationalInfo={nationalInfo}
            setNationalInfo={setNationalInfo}
            handleUpLoadChange={handleUpLoadChange}
            nationalDocReader={nationalDocReader}
            nationalDocBackReader={nationalDocBackReader}
            setNationalDoc={setNationalDoc}
            nationalDoc={nationalDoc}
            nationalDocBack={nationalDocBack}
            setNationalDocBack={setNationalDocBack}
            view={view}
            vlnID={userLogin?.Results[0]?.vln_id}
            setView={setView}
            index={props.location.state?.stepNo}
          />
        ) : view === 3 ? (
          <PassForm
            volunteerNationality={volunteerNationality}
            passportInfo={passportInfo}
            setPassportInfo={setPassportInfo}
            handleUpLoadChange={handleUpLoadChange}
            view={view}
            passportReader={passportReader}
            setPassport={setPassport}
            passport={passport}
            vlnID={userLogin?.Results[0]?.vln_id}
            setView={setView}
            index={props.location.state?.stepNo}
          />
        ) : view === 4 ? (
          <ResiForm
            residenceInfo={residenceInfo}
            setResidenceInfo={setResidenceInfo}
            handleUpLoadChange={handleUpLoadChange}
            view={view}
            residenceReader={residenceReader}
            setResidence={setResidence}
            residence={residence}
            vlnID={userLogin?.Results[0]?.vln_id}
            setView={setView}
            index={props.location.state?.stepNo}
          />
        ) : view === 5 ? (
          <EduForm
            volunteerNationality={volunteerNationality}
            educationInfo={educationInfo}
            setEducationInfo={setEducationInfo}
            handleUpLoadChange={handleUpLoadChange}
            view={view}
            educationReader={educationReader}
            setEducation={setEducation}
            education={education}
            vlnID={userLogin?.Results[0]?.vln_id}
            setView={setView}
            index={props.location.state?.stepNo}
          />
        ) : view === 6 ? (
          <VolunteerIntroForm
            setIntroInfo={setIntroInfo}
            handleUpLoadChange={handleUpLoadChange}
            view={view}
            volunteerIntroReader={volunteerIntroReader}
            volunteerIntro={volunteerIntro}
            setVolunteerIntro={setVolunteerIntro}
            vlnID={userLogin?.Results[0]?.vln_id}
            setView={setView}
            index={props.location.state?.stepNo}
          />
        ) : view === 7 ? (
          <JobForm
            volunteerNationality={volunteerNationality}
            nationalInfo={nationalInfo}
            passportInfo={passportInfo}
            residenceInfo={residenceInfo}
            educationInfo={educationInfo}
            pictureInfo={pictureInfo}
            introInfo={introInfo}
            jobInfo={jobInfo}
            setJobInfo={setJobInfo}
            handleUpLoadChange={handleUpLoadChange}
            jobReader={jobReader}
            setJob={setJob}
            job={job}
            userProfile={userProfile}
            view={view}
            vlnID={userLogin?.Results[0]?.vln_id}
            setView={setView}
            index={props.location.state?.stepNo}
          />
        ) : view === 8 ? (
          <LanguagesForm
            vlnID={userLogin?.Results[0]?.vln_id}
            setView={setView}
            index={view}
          />
        ) : view === 9 ? (
          <InterestsForm
            vlnID={userLogin?.Results[0]?.vln_id}
            setView={setView}
            index={view}
          />
        ) : view === 10 ? (
          <SkillsForm
            vlnID={userLogin?.Results[0]?.vln_id}
            setView={setView}
            index={view}
          />
        ) : (
          <div
            style={{
              minHeight: '700px',
              flexDirection: 'column',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%',
              gap: '10px'
            }}
          >
            <svg
              className="svg_right_sign"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 130.2 130.2"
            >
              <circle
                className="path circle_for_right"
                fill="none"
                stroke="#ee4236"
                stroke-width="6"
                stroke-miterlimit="10"
                cx="65.1"
                cy="65.1"
                r="62.1"
              />
              <polyline
                className="path check"
                fill="none"
                stroke="#ee4236"
                stroke-width="6"
                stroke-linecap="round"
                stroke-miterlimit="10"
                points="100.2,40.2 51.5,88.8 29.8,67.5 "
              />
            </svg>
            <h2>{t('Thanks for your Registration')}</h2>
            <label
              style={{
                textAlign: 'center',
                direction: lng === 'arab' ? 'rtl' : 'ltr'
              }}
            >
              {volunteerSuccessMessage}{' '}
            </label>
          </div>
        )}
      </div>
    );
  }
};
export default Register;
